import React, { ReactNode, useCallback } from 'react';

import { TeamNanoID } from '../../../../../../teams/teamsTypes';

import { useReactQueryState } from '../../../../../../common/hooks/base/reactQuery/useReactQueryState';
import { useCompactStockSecondaryMenu } from '../../../../../../common/components/menus/CompactStockSecondaryMenu/hooks/useCompactStockSecondaryMenu';

import { SecondaryMenuWrapper } from '../../../../../../../app/components/menus/SecondaryMenuWrapper';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { selectProductsMenuKey } from '../../../../../productsConstants';

interface ProductsIndexPageMenuWrapperProps {
  companyNanoId?: TeamNanoID;
  isModal?: boolean;
  children: ReactNode;
}

function ProductsIndexPageMenuWrapper({
  companyNanoId,
  isModal,
  children
}: ProductsIndexPageMenuWrapperProps) {
  const { activeMenu, i18nMenuTitle, menuLinks } =
    useCompactStockSecondaryMenu(companyNanoId);

  const { setValue: setIsOpenMenu, value: isOpenMenu } =
    useReactQueryState<boolean>(selectProductsMenuKey, true);

  const onClose = useCallback<() => void>(() => {
    setIsOpenMenu(false);
  }, [setIsOpenMenu]);

  return isModal ? (
    <div
      className={
        isOpenMenu
          ? 'flex flex-col w-80 4xl:w-112 absolute xl:relative border-r dark:border-gray-700 left-0 top-0 bottom-0 max-h-full max-w-full z-10 bg-white dark:bg-gray-900 xl:bg-transparent xl:dark:bg-transparent'
          : 'hidden'
      }
    >
      <div className="flex flex-col max-h-full flex-1 overflow-y-auto py-4">
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
          icon={IconsEnum.X_OUTLINE}
          onClick={onClose}
        />
        <div id="nav-3d-stock">{children}</div>
      </div>
    </div>
  ) : (
    <SecondaryMenuWrapper
      activeMenu={activeMenu}
      i18nTitle={i18nMenuTitle}
      isCompact
      menuLinks={menuLinks}
    >
      {children}
    </SecondaryMenuWrapper>
  );
}

export default ProductsIndexPageMenuWrapper;
