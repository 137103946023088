import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';

import { IconsEnum } from '../../../../assets/icons/types';
import {
  ChangeProductsFiltersFunc,
  FetchProductsFilterFields,
  FetchProductsFilterProducts,
  FetchProductsFilters,
  FetchProductsSortTypes,
  ProductClientID,
  ProductsAiSearchEnabled
} from '../../../products/productsTypes';

import { useMountEffect } from '../../../../common/hooks/useMountEffect';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { CompactStockSecondaryMenu } from '../../../common/components/menus/CompactStockSecondaryMenu';
import { IndexLayout } from '../../../common/layouts/IndexLayout';
import { ProductsAiSearchClipGlobalModalButton } from '../../../products/components/containers/ProductsAiSearch/components/ProductsAiSearchClipGlobalModalButton';
import {
  ProductsAiSearchOnClear,
  ProductsAiSearchOnClipGlobalSearch
} from '../../../products/components/containers/ProductsAiSearch';
import { ThreeDStockContentProducts } from '../../components/contents/ThreeDStockContent/components/ThreeDStockContentProducts';

import { TextFilter } from '../../../../helpers/filters/TextFilter';

import { ProductPath } from '../../../products/ProductPath';
import { StockPermissions } from '../../stockConstants';
import { FetchItemsSort } from '../../../../types';
import {
  INITIAL_PRODUCTS_SORT,
  ProductsPermissions
} from '../../../products/productsConstants';

import { aiSearchKeys, productsKeys } from '../../../../locales/keys';
import { CacheProductsSearchSuggestInput } from '../../../products/helpers/CacheProductsSearchSuggestInput';

interface ThreeDStockProductsCategoriesPageProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
  clientIds?: ProductClientID[];
  filterProducts: FetchProductsFilterProducts;
  initialFilters?: FetchProductsFilters;
  onAiClipGlobalSearch: ProductsAiSearchOnClipGlobalSearch;
  onClearAiSearch: ProductsAiSearchOnClear;
  productsFilters: FetchProductsFilters;
  sortProducts: (nextSort: FetchItemsSort) => void;
  withAiClipGlobalSearch?: boolean;
  withAiClipGlobalSearchFilter?: boolean;
}

function ThreeDStockProductsCategoriesPage({
  aiSearchEnabled,
  changeProductsFilters,
  clientIds,
  filterProducts,
  initialFilters,
  onAiClipGlobalSearch,
  onClearAiSearch,
  productsFilters,
  withAiClipGlobalSearch,
  withAiClipGlobalSearchFilter,
  sortProducts
}: ThreeDStockProductsCategoriesPageProps) {
  const router = useRouter();
  const currentUser = useCurrentUser();

  const handleSearch = useCallback<
    (
      changedFilters: { [nameSkuTags: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ nameSkuTags }, removeFilters) => {
      changeProductsFilters({ nameSkuTags }, removeFilters);

      const sort = isEmpty(removeFilters)
        ? [FetchProductsSortTypes.SCORE_DESC]
        : INITIAL_PRODUCTS_SORT;

      sortProducts(sort);

      return router.push(ProductPath.index());
    },
    [changeProductsFilters, router, sortProducts]
  );

  const handleSearchWithSuggest = useCallback<(nameSkuTags: string) => void>(
    (nameSkuTags) => {
      if (!nameSkuTags) {
        handleSearch({ nameSkuTags }, [
          FetchProductsFilterFields.NAME_SKU_TAGS
        ]);
      }
      handleSearch({ nameSkuTags }, []);
    },
    [handleSearch]
  );

  const handleAiClipGlobalSearch =
    useCallback<ProductsAiSearchOnClipGlobalSearch>(
      (data) => {
        onAiClipGlobalSearch(data);

        return router.push(ProductPath.index());
      },
      [onAiClipGlobalSearch, router]
    );

  const handleAiClipGlobalSearchFilter =
    useCallback<ProductsAiSearchOnClipGlobalSearch>(
      (data) => {
        changeProductsFilters({
          similarImage: {
            imageUrl: data.fileAttachment.file,
            projectId: null,
            taskId: null
          },
          nda: false,
          blocked: false
        });
        sortProducts([FetchProductsSortTypes.SCORE_DESC]);

        return router.push(ProductPath.index());
      },
      [changeProductsFilters, router, sortProducts]
    );

  useMountEffect(() => {
    filterProducts(initialFilters);
    onClearAiSearch();
  });

  const withSuggestInput = currentUser.hasPermissions(
    ProductsPermissions.READ_PRODUCTS_SEARCH_SUGGEST_LIST
  );

  return (
    <IndexLayout
      action={StockPermissions.READ_THREE_D_STOCK_PRODUCTS_INDEX_PAGE}
      contentClassName="flex flex-col min-w-0"
      contentContainerClassName="py-8 flex justify-center"
      customSecondaryMenu={
        <CompactStockSecondaryMenu productsFilters={productsFilters} />
      }
      i18nTitle={productsKeys.threeDStock}
      withScrollToTop
      withoutHeader
    >
      <div className="p-4">
        <div className="relative">
          {withSuggestInput ? (
            <CacheProductsSearchSuggestInput
              value={productsFilters.nameSkuTags || ''}
              onSubmit={handleSearchWithSuggest}
              icon={IconsEnum.SEARCH_OUTLINE}
              iconClassName="h-8 w-8 text-gray-500 peer-focus:text-current pointer-events-none"
              iconWrapperClassName="absolute left-4 top-4"
              inputClassName="peer pl-16 pr-4 py-4 w-full leading-8 rounded-full bg-transparent hover:bg-gray-100 dark:hover:bg-gray-850 focus:bg-gray-100 dark:focus:bg-gray-850 dark:focus:shadow-2xl focus:ring-8 focus:ring-blue-200 border-none text-2xl font-light"
            />
          ) : (
            <TextFilter
              changeOnEnter
              dataGa="products-text-filter-input"
              disabled={aiSearchEnabled}
              i18nPlaceholder={productsKeys.search}
              icon={IconsEnum.SEARCH_OUTLINE}
              iconClassName="h-8 w-8 text-gray-500 peer-focus:text-current pointer-events-none"
              iconWrapperClassName="absolute left-4 top-4"
              id="products-text-filter-input"
              inputClassName="peer pl-16 pr-4 py-4 w-full leading-8 rounded-full bg-transparent hover:bg-gray-100 dark:hover:bg-gray-850 focus:bg-gray-100 dark:focus:bg-gray-850 dark:focus:shadow-2xl focus:ring-8 focus:ring-blue-200 border-none text-2xl font-light"
              name={FetchProductsFilterFields.NAME_SKU_TAGS}
              onChange={handleSearch}
              value={productsFilters.nameSkuTags || ''}
            />
          )}

          <div className="absolute right-3 top-3 flex">
            {withAiClipGlobalSearchFilter && (
              <ProductsAiSearchClipGlobalModalButton
                className="py-2 pl-2 pr-2 sm:pr-4 sm:space-x-1 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-purple-500 hover:bg-purple-600 shadow-sm hover:shadow-md"
                dataGa="products-clip-global-search-filter-button"
                i18nText={aiSearchKeys.imageSearch}
                i18nTextClassName="sr-only sm:not-sr-only"
                i18nTitle={aiSearchKeys.imageSearch}
                icon={IconsEnum.PHOTO_SOLID}
                iconClassName="h-6 w-6 p-0.5"
                onSubmit={handleAiClipGlobalSearchFilter}
              />
            )}

            {withAiClipGlobalSearch && (
              <ProductsAiSearchClipGlobalModalButton
                className="py-2 pl-2 pr-2 sm:pr-4 sm:space-x-1 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-purple-500 hover:bg-purple-600 shadow-sm hover:shadow-md"
                dataGa="products-clip-global-search-button"
                i18nText={aiSearchKeys.imageSearch}
                i18nTextClassName="sr-only sm:not-sr-only"
                i18nTitle={aiSearchKeys.imageSearch}
                icon={IconsEnum.PHOTO_SOLID}
                iconClassName="h-6 w-6 p-0.5"
                onSubmit={handleAiClipGlobalSearch}
                tooltipI18nText={aiSearchKeys.imageSearch}
              />
            )}
          </div>
        </div>
      </div>

      <ThreeDStockContentProducts
        clientIds={clientIds}
        productsFilters={productsFilters}
      />
    </IndexLayout>
  );
}

export default ThreeDStockProductsCategoriesPage;
